<template>
  <div ref="reviewObserverTarget">
    <div
      class="flex flex-col flex-wrap items-start justify-between gap-32"
      data-qa="user-comment"
    >
      <!-- Customer info -->
      <div class="flex items-start">
        <RevAvatar
          class="mr-12 shrink-0"
          :class="avatarBackground"
          :label="avatarLabel"
          size="medium"
          :thumbnail="authIconAvatar"
        />

        <div class="flex-1">
          <p class="body-1-bold">
            {{ userName }}
          </p>

          <div class="block items-center gap-8 md:flex">
            <p
              v-if="formattedPurchaseDate"
              class="text-static-default-low body-2"
            >
              {{ formattedPurchaseDate }}
            </p>

            <span
              class="bg-static-info-max text-static-default-hi rounded-full caption mt-8 flex h-24 w-fit shrink-0 items-center py-4 pl-4 pr-8 md:mt-0"
            >
              <IconCheckLarge class="mr-4" size="16" />

              {{ i18n(translations.verifiedPurchase) }}
            </span>
          </div>
        </div>
      </div>

      <RevRating :score="averageRate" size="small" />
    </div>

    <!-- PhotosSlider (or any other component) can be injected here-->
    <slot />

    <ReviewText
      :comment="mainReviewComment"
      data-test="main-review-comment"
      :force-expand="expandComment"
      :tracking="trackingData.onClick"
    />
    <!-- Translation -->
    <div
      v-if="isTranslatable"
      class="mt-24 flex flex-col flex-wrap items-start md:mt-32 md:flex-row md:items-center"
    >
      <div v-if="isTranslated" class="mb-8 mr-4 flex items-center md:mb-0">
        <IconSparkles class="mr-4" />

        {{ translatedFrom }}

        <span class="ml-4 hidden md:block"> - </span>
      </div>

      <RevLink
        data-test="toggle-translation-button"
        @click="onToggleTranslation"
      >
        {{ toggleTranslationLabel }}
      </RevLink>
    </div>

    <ProductDetails
      v-if="!displayProductLink && isProductDetailsEnabled"
      :backboxGrade="salesOrderItem?.backboxGrade"
      class="mt-32"
      :title="product.title"
    />

    <!-- Comment date -->
    <p class="text-static-default-low body-2 mt-32">
      {{ commentDate }}
    </p>

    <!-- Product link -->
    <template v-if="displayProductLink && product.title">
      <RevButtonBase
        v-if="productRouteExists"
        class="mt-32 block"
        rel="nofollow"
        :to="productLink"
        :tracking
      >
        <RevTag :label="product.title" variant="secondary" />
      </RevButtonBase>

      <RevTag v-else :label="product.title" variant="secondary" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'
import { computed, ref, toRef, watch } from 'vue'

import type { Link, LinkInternal } from '@backmarket/http-api'
import type { SalesOrderItem } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { RevAvatar } from '@ds/components/Avatar'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevLink } from '@ds/components/Link'
import { RevRating } from '@ds/components/Rating'
import { RevTag } from '@ds/components/Tag'
import { IconCheckLarge } from '@ds/icons/IconCheckLarge'
import { IconSparkles } from '@ds/icons/IconSparkles'

import translations from './UserComment.translations'
import ProductDetails from './components/ProductDetails.vue'
import ReviewText from './components/ReviewText.vue'
import { useAvatar } from './useAvatar'
import {
  useReviewImpressionTracker,
  useTrackReviewImpressionInViewport,
} from './useTrackReviewImpression'
import { useTranslateComment } from './useTranslateComment'

export type UserComment = {
  id: string
  comment: string
  averageRate: number
  translatedComment?: string
  customer: { firstName?: string; lastName?: string }
  countryCode: string
  reviewDate: string
  purchaseDate: string
  product: {
    title: string
    link: Link | string
  }
  languageCode: string
  salesOrderItem: SalesOrderItem
}
export type UserCommentProps = UserComment & {
  trackingData?: {
    onClick: Record<string, unknown>
    onImpression: Record<string, unknown>
  }
  trackReviewImpression?: boolean
}

const props = withDefaults(
  defineProps<
    UserComment & {
      trackingData?: {
        onClick: Record<string, unknown>
        onImpression: Record<string, unknown>
      }
      trackReviewImpression?: boolean
      displayProductLink?: boolean
    }
  >(),
  {
    trackingData: () => ({
      onClick: {},
      onImpression: {},
    }),
    translatedComment: undefined,
    trackReviewImpression: false,
    displayProductLink: true,
  },
)

const router = useRouter()
const route = useRoute()
const experiments = useExperiments()

const isProductDetailsEnabled = computed(() => {
  return (
    experiments['experiment.productDetailsInReview'] === 'withProductDetails'
  )
})

const productRouteExists = computed(() => {
  try {
    return router.resolve(toNuxtLink(props.product.link)).matched.length > 0
  } catch {
    return false
  }
})

const i18n = useI18n()
const { trackExtendedReviewImpression } = useReviewImpressionTracker()

const userName = computed(() => {
  if (props.customer.firstName && props.customer.lastName) {
    return `${props.customer.firstName} ${props.customer.lastName}`
  }

  return (
    props.customer.firstName ??
    props.customer.lastName ??
    i18n(translations.anonymousUser)
  )
})

const {
  mainReviewComment,
  isTranslated,
  toggleTranslation,
  isTranslatable,
  toggleTranslationLabel,
  translatedFrom,
} = useTranslateComment(props)
const expandComment = ref(false)

watch(
  () => props.translatedComment,
  (newTranslatedComment) => {
    if (newTranslatedComment) {
      expandComment.value = true
    }
  },
)
const { trackClick } = useTracking()
async function onToggleTranslation() {
  trackClick({
    name: isTranslated.value ? 'see_original' : 'translate',
    ...props.trackingData?.onClick,
  })

  await toggleTranslation()
}

const useFormatDateToLongDate = (date: string) =>
  i18n.date(new Date(date), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

const formattedPurchaseDate = computed(() =>
  props.purchaseDate
    ? i18n(translations.purchaseDate, {
        date: useFormatDateToLongDate(props.purchaseDate),
      })
    : '',
)

const commentDate = computed(() =>
  i18n(translations.reviewDate, {
    country: i18n.country(props.countryCode),
    date: useFormatDateToLongDate(props.reviewDate),
  }),
)

const {
  label: avatarLabel,
  thumbnail: authIconAvatar,
  backgroundColor: avatarBackground,
} = useAvatar(toRef(props.customer, 'firstName'))

const reviewObserverTarget = ref<null | HTMLElement>(null)

if (props.trackReviewImpression)
  useTrackReviewImpressionInViewport(reviewObserverTarget, {
    userComment: props,
    isTranslated,
  })

watch(
  () => props.id,
  () => {
    if (props.trackReviewImpression) {
      trackExtendedReviewImpression({
        userComment: props,
        isTranslated: isTranslated.value,
      })
    }
  },
)

function isInternalLink(link: Link): link is LinkInternal {
  return 'name' in link
}

const productLink = computed(() => {
  if (!isTranslatable) {
    return props.product.link
  }

  const { market } = useMarketplace()

  const locale = toBcp47Locale(market.defaultLocale)

  if (typeof props.product.link === 'string') {
    return props.product.link.replace(/[a-z]{2}-[a-z]{2}/, locale)
  }

  if (isInternalLink(props.product.link)) {
    const { name, params } = props.product.link

    return { name, params: { ...params, locale } }
  }

  return props.product.link
})

const tracking = computed(() => ({
  name: 'PP_link_review',
  page_type: String(route.name),
}))
</script>
