<template>
  <div class="flex gap-8 overflow-x-auto">
    <div
      v-if="gradeData"
      class="bg-surface-default-hi rounded-md shrink-0 px-12 py-8"
    >
      <div class="flex items-center gap-2">
        <p class="text-static-default-low caption">
          {{ i18n(translations.productDetailsCondition) }}
        </p>
        <RevTooltip :modifiers="TOOLTIP_MODIFIER" position="top">
          <template #trigger="{ show, hide }">
            <IconInfo
              size="16"
              @click="show"
              @focusin="show"
              @focusout="hide"
              @mouseenter="show"
              @mouseleave="hide"
            />
          </template>
          {{ gradeData.description }}
        </RevTooltip>
      </div>

      <p class="text-action-default-hi body-2">{{ gradeData.name }}</p>
    </div>

    <div class="bg-surface-default-hi rounded-md shrink-0 px-12 py-8">
      <p class="text-static-default-low caption">
        {{ i18n(translations.productDetailsItem) }}
      </p>
      <p class="text-action-default-hi body-2">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Grade } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTooltip } from '@ds/components/Tooltip'
import { IconInfo } from '@ds/icons/IconInfo'

import translations from '../UserComment.translations'

const props = defineProps<{
  title: string
  backboxGrade: Grade | null
}>()

const i18n = useI18n()

const TOOLTIP_MODIFIER = [
  {
    name: 'offset',
    options: {
      offset: [25, 8],
    },
  },
]

const gradeData = computed(() => {
  switch (props.backboxGrade?.name) {
    case 'ECO':
      return {
        name: i18n(translations.ecoGrade),
        description: i18n(translations.ecoGradeDescription),
      }
    case 'VERY_GOOD':
      return {
        name: i18n(translations.veryGoodGrade),
        description: i18n(translations.veryGoodGradeDescription),
      }
    case 'FLAWLESS':
      return {
        name: i18n(translations.flawlessGrade),
        description: i18n(translations.flawlessGradeDescription),
      }
    case 'PREMIUM':
      return {
        name: i18n(translations.premiumGrade),
        description: i18n(translations.premiumGradeDescription),
      }
    default:
      return null
  }
})
</script>
