import { type Ref, computed } from 'vue'

import { isCJKCharacter } from '@backmarket/utils/string/isCJKCharacter'
import { tw } from '@backmarket/utils/string/tw'
import { IconAvatar } from '@ds/icons/IconAvatar'

// TODO: this was copy-pasted from front-office/composables. It needs to be migrated to a separate package
export const AVATAR_COLORS = [
  tw`bg-static-default-mid`,
  tw`bg-static-warning-mid`,
  tw`bg-static-danger-mid`,
  tw`bg-static-success-mid`,
  tw`bg-static-info-mid`,
]

export function useAvatar(name: Ref<string | undefined>) {
  const firstName = computed(() => name?.value || '')
  const hasCJKFirstName = computed(() => isCJKCharacter(firstName.value))

  const label = computed(() => {
    if (hasCJKFirstName.value) return firstName.value

    return firstName.value.charAt(0)
  })

  const thumbnail = computed(() =>
    !firstName.value || hasCJKFirstName.value ? IconAvatar : undefined,
  )

  const backgroundColor =
    AVATAR_COLORS[firstName.value.charCodeAt(0) % AVATAR_COLORS.length]

  return {
    label,
    thumbnail,
    backgroundColor,
  }
}
