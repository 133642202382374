export default {
  reviewDate: {
    id: 'product_reviews_user_comment_review_date',
    defaultMessage: 'Reviewed in {country} on {date}',
  },
  purchaseDate: {
    id: 'product_reviews_user_comment_purchase_date',
    defaultMessage: 'Purchased on {date}',
  },
  reviewUpdateDate: {
    id: 'product_reviews_user_comment_update_date',
    defaultMessage: 'Updated in {country} on {date}',
  },
  showMore: {
    id: 'product_reviews_user_comment_more',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'product_reviews_user_comment_less',
    defaultMessage: 'Show less',
  },
  textTranslatedFrom: {
    id: 'translated_from',
    defaultMessage: 'Translated from {lang}',
  },
  seeOriginalText: {
    id: 'see_original_text',
    defaultMessage: 'See original',
  },
  translateTextTo: {
    id: 'translate_to',
    defaultMessage: 'Translate to {lang}',
  },
  errorToastTitle: {
    id: 'translation_error_title',
    defaultMessage: 'Oops, something went wrong.',
  },
  errorToastMessage: {
    id: 'translation_error_message',
    defaultMessage:
      'Our server is a bit slow at the moment. Please give it a sec and try again.',
  },
  anonymousUser: {
    id: 'reviews_anonymous_customer',
    defaultMessage: 'An anonymous Backer',
  },
  verifiedPurchase: {
    id: 'product_reviews_verified_purchase',
    defaultMessage: 'Verified purchase',
  },
  productDetailsItem: {
    id: 'product_reviews_product_details_item',
    defaultMessage: 'Item',
  },
  productDetailsCondition: {
    id: 'product_reviews_product_details_condition',
    defaultMessage: 'Condition',
  },
  ecoGrade: {
    id: 'product_reviews_product_details_eco_grade',
    defaultMessage: 'Fair',
  },
  veryGoodGrade: {
    id: 'product_reviews_product_details_very_good_grade',
    defaultMessage: 'Good',
  },
  flawlessGrade: {
    id: 'product_reviews_product_details_flawless_grade',
    defaultMessage: 'Excellent',
  },
  premiumGrade: {
    id: 'product_reviews_product_details_premium_grade',
    defaultMessage: 'Premium',
  },
  ecoGradeDescription: {
    id: 'product_reviews_product_details_eco_grade_description',
    defaultMessage:
      'Signs of wear. The body may have a few visible scratches and dents. Items with screens may have light scratches.',
  },
  veryGoodGradeDescription: {
    id: 'product_reviews_product_details_very_good_grade_description',
    defaultMessage:
      'Light signs of wear. The body may have light micro-scratches. For items with screens, the screen will have no scratches.',
  },
  flawlessGradeDescription: {
    id: 'product_reviews_product_details_flawless_grade_description',
    defaultMessage:
      'Almost no signs of wear. The body may have very light micro-scratches. For items with screens, the screen will have no scratches.',
  },
  premiumGradeDescription: {
    id: 'product_reviews_product_details_premium_grade_description',
    defaultMessage:
      'Close to perfection. The body may have tiny micro-scratches. For items with screens, the screen will be flawless.',
  },
} as const
